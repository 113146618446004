import { GridColumns } from 'designsystem/components/3-organisms/GridColumns';
import { ModuleFeature } from '../DynamicModule';

export const GridColumnsFeature: React.FC<Content.GridColumns> = ({ content, settings }) => {
	const { columnFirst, columnSecond } = content?.properties ?? {};
	const {
		widthRatio,
		noGutter,
		noSpacingFirstColumn,
		noSpacingSecondColumn,
		noTopMargin,
		noBackgroundFirstColumn,
		noBackgroundSecondColumn,
		backgroundColorFirstColumn,
		backgroundColorSecondColumn,
	} = settings?.properties ?? {};
	const childProps = { noBackground: true, spacing: false };
	const firstCheck = columnFirst.every((e) => e.documentType === 'articleMedia' || e.documentType === 'imageContent');
	const secondCheck = columnSecond.every((e) => e.documentType === 'articleMedia' || e.documentType === 'imageContent');
	const contentColumns = [
		{
			content: columnFirst.map((sectionModule, index) => (
				<div key={index} className="u-child-margin--none u-display-flex-as-block">
					<ModuleFeature contentModule={sectionModule} additionalProps={childProps} area="contentSection" />
				</div>
			)),
			noSpacing: noSpacingFirstColumn,
			noBackground: noBackgroundFirstColumn == 'True',
			backgroundColor: backgroundColorFirstColumn && firstCheck ? backgroundColorFirstColumn : 'White',
		},
		{
			content: columnSecond.map((sectionModule, index) => (
				<div key={index} className="u-child-margin--none u-display-flex-as-block">
					<ModuleFeature contentModule={sectionModule} additionalProps={childProps} area="contentSection" />
				</div>
			)),
			noSpacing: noSpacingSecondColumn,
			noBackground: noBackgroundSecondColumn == 'True',
			backgroundColor: backgroundColorSecondColumn && secondCheck ? backgroundColorSecondColumn : 'White',
		},
	];
	return (
		<GridColumns widthRatio={widthRatio ? widthRatio : '50/50'} noGutter={noGutter} noTopMargin={noTopMargin} contentColumns={contentColumns} />
	);
};
